@import './themes';
@mixin nb-overrides() {
  nb-select.size-medium button {
    padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;
    nb-icon {
      right: 0.41rem !important;
    }
  }
}

.anticon,
.anticon svg {
  vertical-align: inherit;
}
nb-layout-header {
  z-index: 99 !important;
}

app-vicoders-ng-confirm-modal {
  .close {
    outline: none !important;
  }
}

vicoders-ng-modal-backdrop {
  z-index: 800 !important;
}

vicoders-ng-modal-window.modal {
  z-index: 999;
}

.nb-global-scrollblock {
  overflow-y: visible !important;
  position: static !important;
}

@media (max-width: 1024px) {
  .table-responsive-custom {
    .actions {
      visibility: visible !important;
    }
  }
}

@media (max-width: 575.98px) {
  .view,
  .pagination-custom {
    width: 100%;
  }
  .view {
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 732.98px) {
  .table-responsive-custom {
    overflow-x: auto;
    width: 100%;
    white-space: nowrap;
  }
}
.ant-dropdown {
  .ant-dropdown-menu-item {
    padding: 10px;
    a {
      font-size: 14px;
      color: #707070;
    }
    i {
      color: #707070;
      margin-right: 10px;
      font-size: 16px;
      height: 18px;
      width: 18px;
    }
    &:last-child {
      a {
        color: #d30000;
      }
      i.fa-trash-alt {
        color: #d30000;
        font-weight: 600;
      }
    }
  }
}

.nf-notify {
  z-index: 2000;
}

.ui-component {
  padding: 20px;
  color: #192038;
  background-color: #fff;
  font-size: 14px;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  overflow: hidden;
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    height: 40px;
    color: #1890ff;
    background: #f7f9fc;
    border-color: #e8e8e8;
    border-bottom: 1px solid #fff;
  }
  .breadcrumb {
    background-color: #fff;
    padding: 0.0001px 0.0001px 10px;
    margin-bottom: 10px;
    .breadcrumb-item {
      font-size: 14px;
    }
  }
  .title {
    h2 {
      padding-bottom: 10px;
      border-bottom: 1px solid #edf1f7;
      color: #2b2f41;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.33;
      margin-bottom: 30px;
    }
  }
  .wrapper {
    .mb-3rem {
      margin-bottom: 3rem;
    }
    .screen-reader {
      li {
        a {
          color: #0073aa;
          padding: 0.2em;
          text-decoration: none;
          .count {
            color: #555d66;
            font-weight: 400;
          }
        }
        a.current {
          color: #000;
          font-weight: 600;
          border: none;
        }
      }
    }
    .tablenav {
      .actions {
        padding: 0 8px 0 0;
        select {
          margin-right: 6px;
          max-width: 12.5rem;
        }
      }
      .bulk-action-selector-top {
        color: #32373c;
        border-color: #7e8993;
        box-shadow: none;
        border-radius: 3px;
        padding: 0 24px 0 8px;
        min-height: 30px;
        background: #fff;
      }
      .button {
        padding: 0 10px;
        min-height: 30px;
        margin: 0 8px 0 0;
        border-width: 1px;
        border-radius: 3px;
      }
      .action {
        color: #0071a1;
        border-color: #0071a1;
        background: #f3f5f6;
      }
      .action:hover {
        background: #f1f1f1;
        border-color: #016087;
        color: #016087;
      }
    }
    table {
      tr {
        .checkbox {
          .label {
            padding-right: 0 !important;
            .text {
              padding-left: 0 !important;
            }
          }
        }
      }
      thead {
        tr {
          th {
            font-weight: 400;
            line-height: 1.25rem;
            color: #192038;
            border: 1px solid transparent;
            background-color: #e7f0f7;
            vertical-align: middle;
            padding: 0.875rem 1.25rem;
            &:last-child {
              text-align: center;
            }
            &.color-theme {
              color: #3366ff;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            .actions {
              a {
                color: #3366ff;
                &:hover {
                  color: #598bff;
                }
                &.del {
                  color: #a00;
                  cursor: pointer;
                  &:hover {
                    color: #dc3232;
                  }
                }
              }
            }
            .status {
              .active,
              .pending,
              .banned {
                font-weight: 500;
              }
              .pending {
                color: #ffcc00;
              }
              .banned {
                color: #222b45;
              }
              .active {
                color: #2ecc71;
              }
              .delete {
                color: #e03b24;
              }
            }
            .verified {
              p.not-verified {
                color: #bdc3c7;
              }
            }
            vertical-align: inherit;
            padding: 0 1.25rem;
            border: 1px solid #f6f6f6;
            .btn-edit,
            .btn-trash,
            .btn-permission {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
              width: 100%;
              padding: 0.6rem 0;
              font-size: 2rem;
              color: #192038;
              cursor: pointer;
            }
            .btn-dot {
              padding: 0.6rem 0;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #192038;
              font-size: 10px;
              background: none;
              border: none;
              outline: none;
            }
            a:hover {
              text-decoration: none;
            }
            .btn-edit:hover,
            .btn-permission:hover {
              color: #36f;
            }
            .btn-trash:hover {
              color: #ff3d71;
            }
            .text-12 p {
              font-size: 12px;
            }
            &:last-child {
              padding: 0;
              height: 1px;
            }
          }
        }
      }
      &.table-striped {
        tbody {
          tr:nth-of-type(odd) {
            background-color: #fff;
          }
          tr:nth-of-type(even) {
            background-color: #e7f0f7;
          }
        }
      }
      // &.table-hover {
      //   tbody {
      //     tr:hover {
      //       color: #212529;
      //       background-color: #edf1f7;
      //     }
      //   }
      // }
    }
    .view {
      .select-items {
        background: white;
        padding: 7px 13px;
        border-radius: 6px;
        border: 1px solid #edf1f7;
      }
    }
    .pagination-custom {
      .number-pages {
        border-radius: 4px;
        border: 1px solid #edf1f7;
        padding: 0.1px 8px;
        text-align: center;
        height: 24px;
        max-width: 55px;
        font-size: 12px;
      }
      // a.pre {
      //   padding: 7px 13px;
      //   border-radius: 6px;
      //   background: #a7a7a7;
      //   color: #fff;
      // }
      // a.next {
      //   padding: 7px 13px;
      //   border-radius: 6px;
      //   background: #ea833a;
      //   color: #fff;
      // }
    }
  }
}

.mb-3 {
  margin-bottom: 30px;
}

nb-layout-column.full-width {
  background: white;
}

.nb-theme-dark {
  nb-layout-column.full-width {
    background: #222b45;
  }
  .ant-dropdown-menu {
    background-color: #222b45;
    .ant-dropdown-menu-item {
      i {
        color: #ffff !important;
      }
    }
  }
  .ui-component {
    .ant-tabs.ant-tabs-card {
      .ant-tabs-bar {
        border-bottom: 1px solid #192038;
      }
      .ant-tabs-card-bar {
        .ant-tabs-tab {
          color: white;
          background: #343d57;
          border: 1px solid #192038;
        }
        .ant-tabs-tab-active {
          height: 40px;
          color: #1890ff;
          background: #192038;
          border-color: #192038;
          border-bottom: 1px solid #192038;
        }
      }
    }
    color: #fff;
    background-color: #222b45;
    .breadcrumb {
      background-color: #222b45;
    }
    .title {
      h2 {
        color: #ffff;
        border-bottom: 1px solid #151a30;
      }
    }
    .wrapper {
      .screen-reader {
        li {
          a {
            color: #3366ff;
            .count {
              color: #fff;
            }
          }
          a.current {
            color: #fff;
            font-weight: 600;
            border: none;
          }
        }
      }
      table {
        &.table-hover {
          tbody {
            tr:hover {
              background-color: #151a30;
            }
          }
        }
        tr {
          th {
            border: 1px solid #151a30;
            color: #fff;
          }
          td {
            color: #ffff;
            border: 1px solid #151a30;
            .btn-edit,
            .btn-dot {
              color: #ffff;
            }
          }
        }
      }
      .table-striped tbody tr:nth-of-type(odd) {
        background-color: #192038;
        color: #ffff;
      }
      .view {
        .select-items {
          border: 1px solid #151a30;
          background-color: #192038;
          &:hover {
            background-color: #151a30;
          }
        }
      }
      .pagination-custom {
        .number-pages {
          border: 1px solid #151a30;
          background-color: #192038;
          &:hover {
            background-color: #151a30;
          }
        }
        .pre,
        .next {
          background: #14192f;
          &:hover {
            background-color: #151a30;
          }
        }
      }
    }
  }
}

.nb-theme-cosmic {
  nb-layout-column.full-width {
    background: #1b1b38;
  }
  .ant-dropdown-menu {
    background-color: #323259;
    .ant-dropdown-menu-item {
      i {
        color: #ffff !important;
      }
    }
  }
  .ui-component {
    .ant-tabs.ant-tabs-card {
      .ant-tabs-bar {
        border-bottom: 1px solid #192038;
      }
      .ant-tabs-card-bar {
        .ant-tabs-tab {
          color: white;
          background: #47476e;
          border: 1px solid #192038;
        }
        .ant-tabs-tab-active {
          height: 40px;
          color: #1890ff;
          background: #222343;
          border-color: #192038;
          border-bottom: 1px solid #192038;
        }
      }
    }
    color: #fff;
    background-color: #323259;
    .breadcrumb {
      background-color: #323259;
    }
    .title {
      h2 {
        color: #ffff;
        border-bottom: 1px solid #1b1b38;
      }
    }
    .wrapper {
      .screen-reader {
        li {
          a {
            color: #a16eff;
            .count {
              color: #fff;
            }
          }
          a.current {
            color: #fff;
            font-weight: 600;
            border: none;
          }
        }
      }
      table {
        &.table-hover {
          tbody {
            tr:hover {
              background-color: #1b1b38;
            }
          }
        }
        tr {
          th {
            &.color-theme {
              color: #a16eff;
            }
            border: 1px solid #1b1b38;
            color: #fff;
          }
          td {
            .actions {
              a {
                color: #a16eff;
                &:hover {
                  color: #b18aff;
                }
                &.del {
                  color: #a00;
                  cursor: pointer;
                  &:hover {
                    color: #dc3232;
                  }
                }
              }
            }
            color: #ffff;
            border: 1px solid #1b1b38;
            .btn-edit,
            .btn-dot {
              color: #ffff;
            }
          }
        }
      }
      .table-striped tbody tr:nth-of-type(odd) {
        background-color: #252547;
        color: #ffff;
      }
      .view {
        .select-items {
          border: 1px solid #1b1b38;
          background-color: #252547;
          &:hover {
            background-color: #1b1b38;
          }
        }
      }
      .pagination-custom {
        .number-pages {
          border: 1px solid #1b1b38;
          background-color: #252547;
          &:hover {
            background-color: #1b1b38;
          }
        }
        .pre,
        .next {
          background: #14192f;
          &:hover {
            background-color: #1b1b38;
          }
        }
      }
    }
  }
}

.nb-theme-cosmic {
  .ui-component {
    box-shadow: none;
  }
}
