@import './_icon';
@import './_form';
@import './_btn';
@import './_grid';

%ddHandle {
  line-height: 28px;
  padding: 5px 10px;
  margin: 5px 0;
  cursor: pointer;
  color: #333;
  text-decoration: none;
  font-weight: bold;
  border: 1px solid #ccc;
  background: #fafafa;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  display: flex;

  &:hover {
    background: #fff;
  }

  .dd-handle__left {
    flex-grow: 1;
  }

  .dd-handle__right {
    display: flex;

    .icon {
      width: 50px;
      display: flex;
      justify-content: center;
    }
  }
}

.dd-handle {
  @extend %ddHandle;
}

.menu-editor {
  .btn {
    font-size: 14px;
  }

  .dd {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 13px;
    line-height: 20px;
  }

  .dd-collapsed {
    .dd-list {
      display: none;
    }
  }

  .dd-item {
    .dd-handle {
      @extend %ddHandle;
    }

    button {
      display: block;
      position: relative;
      cursor: pointer;
      float: left;
      width: 25px;
      height: 20px;
      margin: 5px 0;
      padding: 0;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      border: 0;
      background: transparent;
      font-size: 12px;
      line-height: 1;
      text-align: center;
      font-weight: bold;
    }

    button::before {
      content: '+';
      display: block;
      position: absolute;
      width: 100%;
      text-align: center;
      text-indent: 0;
    }

    button[data-action='collapse']::before {
      content: '-';
    }
  }

  .dd-placeholder,
  .dd-empty {
    margin: 5px 0;
    padding: 0;
    min-height: 30px;
    background: #f2fbff;
    border: 1px dashed #b6bcbf;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  .dd-empty {
    border: 1px dashed #bbb;
    min-height: 100px;
    background-color: #e5e5e5;
    background-size: 60px 60px;
    background-position: 0 0, 30px 30px;
  }

  .dd-hover.dd-handle {
    background: #2ea8e5 !important;
  }

  .dd3-content {
    display: block;
    height: 30px;
    margin: 5px 0;
    padding: 5px 10px 5px 40px;
    color: #333;
    text-decoration: none;
    font-weight: bold;
    border: 1px solid #ccc;
    background: #fafafa;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  .dd3-content:hover {
    color: #2ea8e5;
    background: #fff;
  }

  .dd3-item button {
    margin-left: 30px;
  }
}

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;

  .dd-list {
    padding-left: 30px;
  }

  &.dd-dragel {
    position: absolute;
    pointer-events: none;
    z-index: 9999;

    .dd-item .dd-handle {
      margin-top: 0;
    }

    .dd-handle {
      box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid #ccc;
      @extend %ddHandle;
    }

    .dd3-item .dd3-content {
      margin: 0;
    }
  }

  .dd-item {
    position: relative;

    .delete_menu_item_btn,
    .delete_menu_item_btn_cat,
    .edit_menu_item {
      position: absolute;
      width: 40px;
      top: 0; /* no unit */
      right: 0; /* no unit */
      text-align: center;
      height: 38px;
      display: inline-block;
      cursor: pointer;

      .icon {
        margin-top: 15px;
        margin-left: 15px;
      }
    }

    .delete_menu_item_btn {
      right: 0; /* no unit */
      background: #e74c3c;
    }

    .delete_menu_item_btn_cat {
      right: 0; /* no unit */
      background: #e74c3c;
    }

    .edit_menu_item {
      right: 40px;
      background: #3498db;
    }
  }
}
