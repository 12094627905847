.cssicon {
  color: #000;

  &.trash {
    color: #c0392b;
    margin-top: 10px;
    position: absolute;
    width: 9px;
    height: 10px;
    border-left: solid 1px currentColor;
    border-right: solid 1px currentColor;
    border-bottom: solid 1px currentColor;
    border-radius: 0 0 2px 2px;

    &::before {
      content: '';
      position: absolute;
      left: -4px;
      top: -2px;
      width: 17px;
      height: 1px;
      background-color: currentColor;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0; /* no unit */
      top: -5px;
      width: 7px;
      height: 2px;
      border-left: solid 1px currentColor;
      border-right: solid 1px currentColor;
      border-top: solid 1px currentColor;
      border-radius: 4px 4px 0 0;
    }
  }

  &.edit {
    color: #2980b9;
    position: absolute;
    margin-left: 4px;
    margin-top: 10px;
    width: 14px;
    height: 2px;
    border-radius: 1px;
    border: solid 1px currentColor;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);

    &::before {
      content: '';
      position: absolute;
      left: -12px;
      top: -1px;
      width: 0; /* no unit */
      height: 0; /* no unit */
      border-left: solid 5px transparent;
      border-right: solid 5px currentColor;
      border-top: solid 2px transparent;
      border-bottom: solid 2px transparent;
    }
  }
}
