@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
// themes - our custom or/and out of the box themes
@import 'themes';
// framework component themes (styles tied to theme variables)
@import '~@nebular/theme/styles/globals';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/grid';
// loading progress bar theme
@import './pace.theme';
@import './layout';
@import './overrides';
// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();
  // @include nb-auth-global();
  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();
  @include nb-overrides();
}

.custom-modal,
.modal-backdrop {
  z-index: 999 !important;
}

// .table-responsive-custom {
//   overflow-x: scroll;
// }
a {
  text-decoration: none !important;
}

@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}

@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}

.thumnail__image {
  // border: 1px dashed #707070;
  cursor: pointer;
}

.table__thumbnail {
  width: 50px;
  height: 40px;
  object-fit: contain;
  // border-radius: 2px;
  // border: solid 1px #f6f6f6;
}

.submitted {
  .ng-invalid {
    border-color: red;
    &:focus {
      border-color: red;
    }
  }
  label {
    &.ng-invalid {
      color: red;
      &:focus {
        border-color: red;
      }
    }
  }
}

.nf-preloader {
  .nf-preloader-bg {
    z-index: 9999;
    position: fixed;
    left: 0;
    /* no unit */
    top: 0;
    /* no unit */
    right: 0;
    /* no unit */
    bottom: 0;
    /* no unit */
    .progress {
      position: relative;
      height: 4px;
      display: block;
      width: 100%;
      background-color: #acece6;
      border-radius: 2px;
      overflow: hidden;
      .indeterminate {
        background-color: #3366ff;
        &::before {
          content: '';
          position: absolute;
          background-color: inherit;
          top: 0;
          left: 0;
          bottom: 0;
          will-change: left, right;
          -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
          animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
        }
        &::after {
          content: '';
          position: absolute;
          background-color: inherit;
          top: 0;
          left: 0;
          bottom: 0;
          will-change: left, right;
          -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
          animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
          -webkit-animation-delay: 1.15s;
          animation-delay: 1.15s;
        }
      }
    }
  }
}

.no-data {
  padding: 20px 0 60px;
  span {
    color: #707070;
    font-size: 14px;
    a:hover {
      text-decoration: underline;
    }
  }
}

.nb-theme-default nb-menu .menu-group,
.nb-theme-default nb-menu .menu-item a {
  font-weight: unset !important;
}

.nb-theme-default nb-sidebar.compacted {
  .setup_website {
    display: none;
  }
}

.nb-theme-default {
  nb-progress-bar.size_tiny {
    .progress-container {
      height: 4px !important;
    }
  }
}

.nb-theme-default {
  nb-menu {
    .menu-items {
      .menu-item {
        .expand-state {
          color: #e7f0f7 !important;
        }
        // border-bottom: 4px solid #fbfbfb;
        border: none;
        &:nth-child(1),
        &:nth-child(5),
        &:nth-child(7),
        &:nth-child(8) {
          border-bottom: 4px solid #fbfbfb;
          padding: 0.625rem 0;
        }
        &:nth-child(5),
        &:nth-child(7) {
          padding-top: 0;
        }
        &:nth-child(2),
        &:nth-child(6),
        &:nth-child(9) {
          margin-top: 0.625rem;
        }
        .active {
          // css active
          border-radius: 6px;
          background-color: #e6f5ff;
          span {
            color: #3366ff !important;
          }
        }
        a {
          padding: 0.938rem;
          margin: 0 0.625rem;
          // margin-top: 0;
          .menu-title {
            font-size: 16px;
            font-weight: bold;
            line-height: 1.38;
            text-align: left;
            color: #1d1d40;
          }
        }
      }
    }
    .menu-item {
      .menu-items {
        .menu-item {
          border: none;
          margin: 1.25rem 1.6rem;
          &:first-child {
            margin-top: 0.625rem !important;
            padding: 0 !important;
          }
          &:last-child {
            margin-bottom: 0.625rem !important;
            padding: 0 !important;
          }
          a {
            padding: 0 !important;
            background: none;
            margin: 0 !important;
            margin-left: 0.188rem !important;
            &:hover {
              .menu-title {
                color: #3366ff;
              }
            }
            // css active
            .menu-title {
              font-size: 14px;
              font-weight: 600;
              line-height: 1.36;
              text-align: left;
              color: #888999;
            }
          }
        }
      }
      .menu-icon {
        display: flex;
        align-items: center;
      }
    }
  }
}

//===================================== General class ====================================//
// breadcrumb item
.ui-component {
  .breadcrumb-item {
    padding-left: 0.3rem;
    &::before {
      padding-right: 0.3rem;
    }
  }
  .no-before {
    padding-left: unset;
    &::before {
      display: none;
    }
  }
}

// button custom
.btn {
  height: 2.5rem !important;
  font-size: 0.875rem !important;
  font-weight: 600 !important;
  border-radius: 6px !important;
  text-transform: unset !important;
}
