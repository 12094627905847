.menu-editor {
  .btn {
    display: inline-block;
    padding: 0.375rem 1rem;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    &.btn-link {
      &:focus {
        outline: none !important;
        box-shadow: none;
      }
    }
  }

  .btn:focus,
  .btn.focus,
  .btn:active:focus,
  .btn:active.focus,
  .btn.active:focus,
  .btn.active.focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }

  .btn:focus,
  .btn:hover {
    text-decoration: none;
  }

  .btn.focus {
    text-decoration: none;
  }

  .btn:active,
  .btn.active {
    background-image: none;
    outline: 0;
  }

  .btn.disabled,
  .btn:disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }

  a.btn.disabled,
  fieldset[disabled] a.btn {
    pointer-events: none;
  }

  .btn-primary {
    color: #fff;
    background-color: #0275d8;
    border-color: #0275d8;
  }

  .btn-primary:hover {
    color: #fff;
    background-color: #025aa5;
    border-color: #01549b;
  }

  .btn-primary:focus,
  .btn-primary.focus {
    color: #fff;
    background-color: #025aa5;
    border-color: #01549b;
  }

  .btn-primary:active,
  .btn-primary.active,
  .open > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #025aa5;
    background-image: none;
    border-color: #01549b;
  }

  .btn-primary:active:hover,
  .btn-primary:active:focus,
  .btn-primary:active.focus,
  .btn-primary.active:hover,
  .btn-primary.active:focus,
  .btn-primary.active.focus,
  .open > .btn-primary.dropdown-toggle:hover,
  .open > .btn-primary.dropdown-toggle:focus,
  .open > .btn-primary.dropdown-toggle.focus {
    color: #fff;
    background-color: #014682;
    border-color: #01315a;
  }

  .btn-primary.disabled:focus,
  .btn-primary.disabled.focus,
  .btn-primary:disabled:focus,
  .btn-primary:disabled.focus {
    background-color: #0275d8;
    border-color: #0275d8;
  }

  .btn-primary.disabled:hover,
  .btn-primary:disabled:hover {
    background-color: #0275d8;
    border-color: #0275d8;
  }

  .btn-secondary {
    color: #373a3c;
    background-color: #fff;
    border-color: #ccc;
  }

  .btn-secondary:hover {
    color: #373a3c;
    background-color: #e6e6e6;
    border-color: #adadad;
  }

  .btn-secondary:focus,
  .btn-secondary.focus {
    color: #373a3c;
    background-color: #e6e6e6;
    border-color: #adadad;
  }

  .btn-secondary:active,
  .btn-secondary.active,
  .open > .btn-secondary.dropdown-toggle {
    color: #373a3c;
    background-color: #e6e6e6;
    background-image: none;
    border-color: #adadad;
  }

  .btn-secondary:active:hover,
  .btn-secondary:active:focus,
  .btn-secondary:active.focus,
  .btn-secondary.active:hover,
  .btn-secondary.active:focus,
  .btn-secondary.active.focus,
  .open > .btn-secondary.dropdown-toggle:hover,
  .open > .btn-secondary.dropdown-toggle:focus,
  .open > .btn-secondary.dropdown-toggle.focus {
    color: #373a3c;
    background-color: #d4d4d4;
    border-color: #8c8c8c;
  }

  .btn-secondary.disabled:focus,
  .btn-secondary.disabled.focus,
  .btn-secondary:disabled:focus,
  .btn-secondary:disabled.focus {
    background-color: #fff;
    border-color: #ccc;
  }

  .btn-secondary.disabled:hover,
  .btn-secondary:disabled:hover {
    background-color: #fff;
    border-color: #ccc;
  }

  .btn-info {
    color: #fff;
    background-color: #5bc0de;
    border-color: #5bc0de;
  }

  .btn-info:hover {
    color: #fff;
    background-color: #31b0d5;
    border-color: #2aabd2;
  }

  .btn-info:focus,
  .btn-info.focus {
    color: #fff;
    background-color: #31b0d5;
    border-color: #2aabd2;
  }

  .btn-info:active,
  .btn-info.active,
  .open > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #31b0d5;
    background-image: none;
    border-color: #2aabd2;
  }

  .btn-info:active:hover,
  .btn-info:active:focus,
  .btn-info:active.focus,
  .btn-info.active:hover,
  .btn-info.active:focus,
  .btn-info.active.focus,
  .open > .btn-info.dropdown-toggle:hover,
  .open > .btn-info.dropdown-toggle:focus,
  .open > .btn-info.dropdown-toggle.focus {
    color: #fff;
    background-color: #269abc;
    border-color: #1f7e9a;
  }

  .btn-info.disabled:focus,
  .btn-info.disabled.focus,
  .btn-info:disabled:focus,
  .btn-info:disabled.focus {
    background-color: #5bc0de;
    border-color: #5bc0de;
  }

  .btn-info.disabled:hover,
  .btn-info:disabled:hover {
    background-color: #5bc0de;
    border-color: #5bc0de;
  }

  .btn-success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #5cb85c;
  }

  .btn-success:hover {
    color: #fff;
    background-color: #449d44;
    border-color: #419641;
  }

  .btn-success:focus,
  .btn-success.focus {
    color: #fff;
    background-color: #449d44;
    border-color: #419641;
  }

  .btn-success:active,
  .btn-success.active,
  .open > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #449d44;
    background-image: none;
    border-color: #419641;
  }

  .btn-success:active:hover,
  .btn-success:active:focus,
  .btn-success:active.focus,
  .btn-success.active:hover,
  .btn-success.active:focus,
  .btn-success.active.focus,
  .open > .btn-success.dropdown-toggle:hover,
  .open > .btn-success.dropdown-toggle:focus,
  .open > .btn-success.dropdown-toggle.focus {
    color: #fff;
    background-color: #398439;
    border-color: #2d672d;
  }

  .btn-success.disabled:focus,
  .btn-success.disabled.focus,
  .btn-success:disabled:focus,
  .btn-success:disabled.focus {
    background-color: #5cb85c;
    border-color: #5cb85c;
  }

  .btn-success.disabled:hover,
  .btn-success:disabled:hover {
    background-color: #5cb85c;
    border-color: #5cb85c;
  }

  .btn-warning {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #f0ad4e;
  }

  .btn-warning:hover {
    color: #fff;
    background-color: #ec971f;
    border-color: #eb9316;
  }

  .btn-warning:focus,
  .btn-warning.focus {
    color: #fff;
    background-color: #ec971f;
    border-color: #eb9316;
  }

  .btn-warning:active,
  .btn-warning.active,
  .open > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #ec971f;
    background-image: none;
    border-color: #eb9316;
  }

  .btn-warning:active:hover,
  .btn-warning:active:focus,
  .btn-warning:active.focus,
  .btn-warning.active:hover,
  .btn-warning.active:focus,
  .btn-warning.active.focus,
  .open > .btn-warning.dropdown-toggle:hover,
  .open > .btn-warning.dropdown-toggle:focus,
  .open > .btn-warning.dropdown-toggle.focus {
    color: #fff;
    background-color: #d58512;
    border-color: #b06d0f;
  }

  .btn-warning.disabled:focus,
  .btn-warning.disabled.focus,
  .btn-warning:disabled:focus,
  .btn-warning:disabled.focus {
    background-color: #f0ad4e;
    border-color: #f0ad4e;
  }

  .btn-warning.disabled:hover,
  .btn-warning:disabled:hover {
    background-color: #f0ad4e;
    border-color: #f0ad4e;
  }

  .btn-danger {
    color: #fff;
    background-color: #d9534f;
    border-color: #d9534f;
  }

  .btn-danger:hover {
    color: #fff;
    background-color: #c9302c;
    border-color: #c12e2a;
  }

  .btn-danger:focus,
  .btn-danger.focus {
    color: #fff;
    background-color: #c9302c;
    border-color: #c12e2a;
  }

  .btn-danger:active,
  .btn-danger.active,
  .open > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #c9302c;
    background-image: none;
    border-color: #c12e2a;
  }

  .btn-danger:active:hover,
  .btn-danger:active:focus,
  .btn-danger:active.focus,
  .btn-danger.active:hover,
  .btn-danger.active:focus,
  .btn-danger.active.focus,
  .open > .btn-danger.dropdown-toggle:hover,
  .open > .btn-danger.dropdown-toggle:focus,
  .open > .btn-danger.dropdown-toggle.focus {
    color: #fff;
    background-color: #ac2925;
    border-color: #8b211e;
  }

  .btn-danger.disabled:focus,
  .btn-danger.disabled.focus,
  .btn-danger:disabled:focus,
  .btn-danger:disabled.focus {
    background-color: #d9534f;
    border-color: #d9534f;
  }

  .btn-danger.disabled:hover,
  .btn-danger:disabled:hover {
    background-color: #d9534f;
    border-color: #d9534f;
  }

  .btn-primary-outline {
    color: #0275d8;
    background-color: transparent;
    background-image: none;
    border-color: #0275d8;
  }

  .btn-primary-outline:focus,
  .btn-primary-outline.focus,
  .btn-primary-outline:active,
  .btn-primary-outline.active,
  .open > .btn-primary-outline.dropdown-toggle {
    color: #fff;
    background-color: #0275d8;
    border-color: #0275d8;
  }

  .btn-primary-outline:hover {
    color: #fff;
    background-color: #0275d8;
    border-color: #0275d8;
  }

  .btn-primary-outline.disabled:focus,
  .btn-primary-outline.disabled.focus,
  .btn-primary-outline:disabled:focus,
  .btn-primary-outline:disabled.focus {
    border-color: #43a7fd;
  }

  .btn-primary-outline.disabled:hover,
  .btn-primary-outline:disabled:hover {
    border-color: #43a7fd;
  }

  .btn-secondary-outline {
    color: #ccc;
    background-color: transparent;
    background-image: none;
    border-color: #ccc;
  }

  .btn-secondary-outline:focus,
  .btn-secondary-outline.focus,
  .btn-secondary-outline:active,
  .btn-secondary-outline.active,
  .open > .btn-secondary-outline.dropdown-toggle {
    color: #fff;
    background-color: #ccc;
    border-color: #ccc;
  }

  .btn-secondary-outline:hover {
    color: #fff;
    background-color: #ccc;
    border-color: #ccc;
  }

  .btn-secondary-outline.disabled:focus,
  .btn-secondary-outline.disabled.focus,
  .btn-secondary-outline:disabled:focus,
  .btn-secondary-outline:disabled.focus {
    border-color: white;
  }

  .btn-secondary-outline.disabled:hover,
  .btn-secondary-outline:disabled:hover {
    border-color: white;
  }

  .btn-info-outline {
    color: #5bc0de;
    background-color: transparent;
    background-image: none;
    border-color: #5bc0de;
  }

  .btn-info-outline:focus,
  .btn-info-outline.focus,
  .btn-info-outline:active,
  .btn-info-outline.active,
  .open > .btn-info-outline.dropdown-toggle {
    color: #fff;
    background-color: #5bc0de;
    border-color: #5bc0de;
  }

  .btn-info-outline:hover {
    color: #fff;
    background-color: #5bc0de;
    border-color: #5bc0de;
  }

  .btn-info-outline.disabled:focus,
  .btn-info-outline.disabled.focus,
  .btn-info-outline:disabled:focus,
  .btn-info-outline:disabled.focus {
    border-color: #b0e1ef;
  }

  .btn-info-outline.disabled:hover,
  .btn-info-outline:disabled:hover {
    border-color: #b0e1ef;
  }

  .btn-success-outline {
    color: #5cb85c;
    background-color: transparent;
    background-image: none;
    border-color: #5cb85c;
  }

  .btn-success-outline:focus,
  .btn-success-outline.focus,
  .btn-success-outline:active,
  .btn-success-outline.active,
  .open > .btn-success-outline.dropdown-toggle {
    color: #fff;
    background-color: #5cb85c;
    border-color: #5cb85c;
  }

  .btn-success-outline:hover {
    color: #fff;
    background-color: #5cb85c;
    border-color: #5cb85c;
  }

  .btn-success-outline.disabled:focus,
  .btn-success-outline.disabled.focus,
  .btn-success-outline:disabled:focus,
  .btn-success-outline:disabled.focus {
    border-color: #a3d7a3;
  }

  .btn-success-outline.disabled:hover,
  .btn-success-outline:disabled:hover {
    border-color: #a3d7a3;
  }

  .btn-warning-outline {
    color: #f0ad4e;
    background-color: transparent;
    background-image: none;
    border-color: #f0ad4e;
  }

  .btn-warning-outline:focus,
  .btn-warning-outline.focus,
  .btn-warning-outline:active,
  .btn-warning-outline.active,
  .open > .btn-warning-outline.dropdown-toggle {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #f0ad4e;
  }

  .btn-warning-outline:hover {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #f0ad4e;
  }

  .btn-warning-outline.disabled:focus,
  .btn-warning-outline.disabled.focus,
  .btn-warning-outline:disabled:focus,
  .btn-warning-outline:disabled.focus {
    border-color: #f8d9ac;
  }

  .btn-warning-outline.disabled:hover,
  .btn-warning-outline:disabled:hover {
    border-color: #f8d9ac;
  }

  .btn-danger-outline {
    color: #d9534f;
    background-color: transparent;
    background-image: none;
    border-color: #d9534f;
  }

  .btn-danger-outline:focus,
  .btn-danger-outline.focus,
  .btn-danger-outline:active,
  .btn-danger-outline.active,
  .open > .btn-danger-outline.dropdown-toggle {
    color: #fff;
    background-color: #d9534f;
    border-color: #d9534f;
  }

  .btn-danger-outline:hover {
    color: #fff;
    background-color: #d9534f;
    border-color: #d9534f;
  }

  .btn-danger-outline.disabled:focus,
  .btn-danger-outline.disabled.focus,
  .btn-danger-outline:disabled:focus,
  .btn-danger-outline:disabled.focus {
    border-color: #eba5a3;
  }

  .btn-danger-outline.disabled:hover,
  .btn-danger-outline:disabled:hover {
    border-color: #eba5a3;
  }

  .btn-link {
    font-weight: normal;
    color: #0275d8;
    border-radius: 0;
  }

  .btn-link,
  .btn-link:active,
  .btn-link.active,
  .btn-link:disabled {
    background-color: transparent;
  }

  .btn-link,
  .btn-link:focus,
  .btn-link:active {
    border-color: transparent;
  }

  .btn-link:hover {
    border-color: transparent;
  }

  .btn-link:focus,
  .btn-link:hover {
    color: #014c8c;
    text-decoration: underline;
    background-color: transparent;
  }

  .btn-link:disabled:focus,
  .btn-link:disabled:hover {
    color: #818a91;
    text-decoration: none;
  }

  .btn-lg,
  .btn-group-lg > .btn {
    padding: 0.75rem 1.25rem;
    font-size: 1.25rem;
    line-height: 1.333333;
    border-radius: 0.3rem;
  }

  .btn-sm,
  .btn-group-sm > .btn {
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }

  .btn-block {
    display: block;
    width: 100%;
  }

  .btn-block + .btn-block {
    margin-top: 5px;
  }
}
