.menu-editor {
  .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #55595c;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
  }

  .form-control::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  .form-control:focus {
    border-color: #66afe9;
    outline: none;
  }

  .form-control::-webkit-input-placeholder {
    color: #999;
    opacity: 1;
  }

  .form-control::-moz-placeholder {
    color: #999;
    opacity: 1;
  }

  .form-control:-ms-input-placeholder {
    color: #999;
    opacity: 1;
  }

  .form-control::placeholder {
    color: #999;
    opacity: 1;
  }

  .form-control:disabled,
  .form-control[readonly] {
    background-color: #eceeef;
    opacity: 1;
  }

  .form-control:disabled {
    cursor: not-allowed;
  }

  .form-control-file,
  .form-control-range {
    display: block;
  }

  .form-control-label {
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
  }

  .form-control-static {
    min-height: 2.25rem;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    margin-bottom: 0;
  }

  .form-control-static.form-control-sm,
  .input-group-sm > .form-control-static.form-control,
  .input-group-sm > .form-control-static.input-group-addon,
  .input-group-sm > .input-group-btn > .form-control-static.btn,
  .form-control-static.form-control-lg,
  .input-group-lg > .form-control-static.form-control,
  .input-group-lg > .form-control-static.input-group-addon,
  .input-group-lg > .input-group-btn > .form-control-static.btn {
    padding-right: 0;
    padding-left: 0;
  }

  .form-control-sm,
  .input-group-sm > .form-control,
  .input-group-sm > .input-group-addon,
  .input-group-sm > .input-group-btn > .btn {
    padding: 0.275rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }

  .form-control-lg,
  .input-group-lg > .form-control,
  .input-group-lg > .input-group-addon,
  .input-group-lg > .input-group-btn > .btn {
    padding: 0.75rem 1.25rem;
    font-size: 1.25rem;
    line-height: 1.333333;
    border-radius: 0.3rem;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .radio,
  .checkbox {
    position: relative;
    display: block;
    margin-bottom: 0.75rem;
  }

  .radio label,
  .checkbox label {
    padding-left: 1.25rem;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
  }

  .radio label input:only-child,
  .checkbox label input:only-child {
    position: static;
  }

  .radio input[type='radio'],
  .radio-inline input[type='radio'],
  .checkbox input[type='checkbox'],
  .checkbox-inline input[type='checkbox'] {
    position: absolute;
    margin-top: 0.25rem;
    margin-left: -1.25rem;
  }

  .radio + .radio,
  .checkbox + .checkbox {
    margin-top: -0.25rem;
  }

  .radio-inline,
  .checkbox-inline {
    position: relative;
    display: inline-block;
    padding-left: 1.25rem;
    margin-bottom: 0;
    font-weight: normal;
    vertical-align: middle;
    cursor: pointer;
  }

  .radio-inline + .radio-inline,
  .checkbox-inline + .checkbox-inline {
    margin-top: 0;
    margin-left: 0.75rem;
  }

  input[type='radio']:disabled,
  input[type='radio'].disabled,
  input[type='checkbox']:disabled,
  input[type='checkbox'].disabled {
    cursor: not-allowed;
  }

  .radio-inline.disabled,
  .checkbox-inline.disabled {
    cursor: not-allowed;
  }

  .radio.disabled label,
  .checkbox.disabled label {
    cursor: not-allowed;
  }

  .form-control-success,
  .form-control-warning,
  .form-control-danger {
    padding-right: 2.25rem;
    background-repeat: no-repeat;
    background-position: center right 0.5625rem;
    -webkit-background-size: 1.4625rem 1.4625rem;
    background-size: 1.4625rem 1.4625rem;
  }

  .has-success .text-help,
  .has-success .form-control-label,
  .has-success .radio,
  .has-success .checkbox,
  .has-success .radio-inline,
  .has-success .checkbox-inline,
  .has-success.radio label,
  .has-success.checkbox label,
  .has-success.radio-inline label,
  .has-success.checkbox-inline label {
    color: #5cb85c;
  }

  .has-success .form-control {
    border-color: #5cb85c;
  }

  .has-success .input-group-addon {
    color: #5cb85c;
    background-color: #eaf6ea;
    border-color: #5cb85c;
  }

  .has-success .form-control-feedback {
    color: #5cb85c;
  }

  .has-success .form-control-success {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==');
  }

  .has-warning .text-help,
  .has-warning .form-control-label,
  .has-warning .radio,
  .has-warning .checkbox,
  .has-warning .radio-inline,
  .has-warning .checkbox-inline,
  .has-warning.radio label,
  .has-warning.checkbox label,
  .has-warning.radio-inline label,
  .has-warning.checkbox-inline label {
    color: #f0ad4e;
  }

  .has-warning .form-control {
    border-color: #f0ad4e;
  }

  .has-warning .input-group-addon {
    color: #f0ad4e;
    background-color: white;
    border-color: #f0ad4e;
  }

  .has-warning .form-control-feedback {
    color: #f0ad4e;
  }

  .has-warning .form-control-warning {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+');
  }

  .has-danger .text-help,
  .has-danger .form-control-label,
  .has-danger .radio,
  .has-danger .checkbox,
  .has-danger .radio-inline,
  .has-danger .checkbox-inline,
  .has-danger.radio label,
  .has-danger.checkbox label,
  .has-danger.radio-inline label,
  .has-danger.checkbox-inline label {
    color: #d9534f;
  }

  .has-danger .form-control {
    border-color: #d9534f;
  }

  .has-danger .input-group-addon {
    color: #d9534f;
    background-color: #fdf7f7;
    border-color: #d9534f;
  }

  .has-danger .form-control-feedback {
    color: #d9534f;
  }

  .has-danger .form-control-danger {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=');
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .menu-editor {
    input[type='date'].form-control,
    input[type='time'].form-control,
    input[type='datetime-local'].form-control,
    input[type='month'].form-control {
      line-height: 2.25rem;
    }
    input[type='date'].input-sm,
    .input-group-sm input[type='date'].form-control,
    input[type='time'].input-sm,
    .input-group-sm input[type='time'].form-control,
    input[type='datetime-local'].input-sm,
    .input-group-sm input[type='datetime-local'].form-control,
    input[type='month'].input-sm,
    .input-group-sm input[type='month'].form-control {
      line-height: 1.8625rem;
    }
    input[type='date'].input-lg,
    .input-group-lg input[type='date'].form-control,
    input[type='time'].input-lg,
    .input-group-lg input[type='time'].form-control,
    input[type='datetime-local'].input-lg,
    .input-group-lg input[type='datetime-local'].form-control,
    input[type='month'].input-lg,
    .input-group-lg input[type='month'].form-control {
      line-height: 3.166667rem;
    }
  }
}
@media (min-width: 544px) {
  .menu-editor {
    .form-inline .form-group {
      display: inline-block;
      margin-bottom: 0;
      vertical-align: middle;
    }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }
    .form-inline .form-control-static {
      display: inline-block;
    }
    .form-inline .input-group {
      display: inline-table;
      vertical-align: middle;
    }
    .form-inline .input-group .input-group-addon,
    .form-inline .input-group .input-group-btn,
    .form-inline .input-group .form-control {
      width: auto;
    }
    .form-inline .input-group > .form-control {
      width: 100%;
    }
    .form-inline .form-control-label {
      margin-bottom: 0;
      vertical-align: middle;
    }
    .form-inline .radio,
    .form-inline .checkbox {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      vertical-align: middle;
    }
    .form-inline .radio label,
    .form-inline .checkbox label {
      padding-left: 0;
    }
    .form-inline .radio input[type='radio'],
    .form-inline .checkbox input[type='checkbox'] {
      position: relative;
      margin-left: 0;
    }
    .form-inline .has-feedback .form-control-feedback {
      top: 0;
    }
  }
}
